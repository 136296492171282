import Container from '@mui/material/Container';
import { Avatar, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FilledInput, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { Event } from '../interfaces/Event';
import { createEvent, getEvents } from '../api/events';
import { formatDatePtBr } from '../utils/dateUtils';
import ButtonLoading from '../components/ButtonLoading';
import Row from '../components/Row';
import Input from '../components/Input';

import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { storage } from "../api/firebase";
import { Title } from '@mui/icons-material';
import { toast } from 'react-toastify';

export default function CreateEvent() {

  const navigate = useNavigate();

  const [events, setEvents] = useState<Event[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const [banner, setBanner] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [subtitle, setSubtitle] = useState<string>('');
  const [dateInit, setDateInit] = useState<string>('');
  const [dateFinal, setDateFinal] = useState<string>('');

  const [days, setDays] = useState<any>([]);
  const [dayTitle, setDayTitle] = useState<string>('');
  const [dayDate, setDayDate] = useState<string>('');
  const [dayCode, setDayCode] = useState<string>('');

  useEffect(() => {
    fetchEvents();
  }, [])

  const fetchEvents = () => {
    getEvents()
      .then((response: Event[]) => {
        setEvents(response);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const handleClose = () => {
    setOpen(false)
  };

  const uploadImage = (inp: any, setInput: any) => {
    if (!inp) return

    const imageRef = ref(storage, `images/${inp.name + new Date().getTime()}`)

    uploadBytes(imageRef, inp)
      .then((result) => {
        getDownloadURL(result.ref)
          .then((imageUpload) => {
            console.log('Imagem enviada com sucesso: ', imageUpload)
            setInput(imageUpload)
            return imageUpload
          })
      })
  }

  const addDay = () => {

    const newDay = {
      title: dayTitle,
      date: new Date(dayDate).toISOString(),
      code: dayCode,
      id: new Date().getTime().toString()
    }

    setDays([...days, newDay])
  }

  const saveEvent = () => {

    if (!title || !subtitle || !dateInit || !dateFinal || !banner || days.length < 1) {
      toast.error('Preencha todos os campos!')
      return
    }

    if (new Date(dateInit).toISOString() > new Date(dateFinal).toISOString()) {
      toast.error('Data inicial maior que data final!')
      return
    }

    if (new Date(dateInit).toISOString() <= new Date().toISOString()) {
      toast.error('Data inicial menor ou igual que data atual!')
      return
    }

    if (new Date(days[0].date).toISOString() < new Date(dateInit).toISOString()) {
      toast.error('Data do primeiro dia deve ser igual a data inicial do evento!')
      return
    }

    if (new Date(days[days.length - 1].date).toISOString() > new Date(dateFinal).toISOString()) {
      toast.error('Data do último dia deve ser igual a data final do evento!')
      return
    }

    const newEvent = {
      id: new Date().getTime().toString(),
      title,
      subtitle,
      banner,
      dateInit: new Date(dateInit).toISOString(),
      dateFinal: new Date(dateFinal).toISOString(),
      days
    }

    createEvent(newEvent)
      .then(() => {
        toast.success('Evento criado com sucesso!')
        handleClose()
        fetchEvents()
      })
      .catch((err) => {
        toast.error('Erro ao criar evento! Tente novamente')
      })

  }

  return (
    <Box sx={styles.container}>

      <ButtonLoading
        label={'Criar evento'}
        style={{ width: 200, height: 50, marginTop: 20 }}
        onClick={() => setOpen(true)}
      />

      <TableContainer component={Paper} sx={{ mt: 6 }}>
        <Table  >
          <TableHead>
            <TableRow>
              <TableCell align="center">Banner</TableCell>
              <TableCell align="center">Titulo</TableCell>
              <TableCell align="center">Descrição</TableCell>
              <TableCell align="center">Inicio</TableCell>
              <TableCell align="center">Final</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events && events.map((event) => (
              <TableRow
                key={event.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="center" style={{ width: 180 }}>
                  <img style={{ width: 180, height: 100 }} src={event.banner} />
                </TableCell>

                <TableCell align="center">{event.title}</TableCell>
                <TableCell align="center">{event.subtitle}</TableCell>
                <TableCell align="center">{formatDatePtBr(new Date(event.dateInit))}</TableCell>
                <TableCell align="center">{formatDatePtBr(new Date(event.dateFinal))}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* /Dialog Create Event */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen
        style={{ padding: 40 }}
      >
        <DialogTitle>Criar evento</DialogTitle>

        <DialogContent >
          <DialogContentText>
            Ensira no campo abaixo o código de checkin informado durante a live
          </DialogContentText>

          <Row style={{ alignItems: 'center' }}>
            <Avatar
              variant="rounded"
              src={banner}
              style={{ width: 210, height: 120, marginTop: 12, marginRight: 12 }}
            />

            <TextField
              type="file"
              id="logo"
              name="logo"
              inputProps={{ accept: "image/png, image/jpeg" }}
              onChange={(e) => {
                const file = (e.target as HTMLInputElement).files?.[0];
                if (file) {
                  uploadImage(file, setBanner);
                }
              }}
            />
          </Row>

          <Input
            title='Titulo do evento'
            placeholder='Digite o titulo do evento'
            value={title}
            style={{ width: '50%' }}
            onChange={(e) => setTitle(e.target.value)}
          />

          <Input
            title='Descição'
            placeholder='Digite a descrição do evento'
            value={subtitle}
            multiline
            rows={4}
            style={{ width: '50%' }}
            onChange={(e) => setSubtitle(e.target.value)}
          />

          <Row style={{ alignItems: 'center', gap: 12 }}>
            <Input
              type='date'
              title='Data inicio'
              placeholder='Digite a data do dia'
              value={dateInit}
              onChange={(e) => setDateInit(e.target.value)}
            />
            <Input
              type='date'
              title='Data final'
              placeholder='Digite a data do dia'
              value={dateFinal}
              onChange={(e) => setDateFinal(e.target.value)}
            />
          </Row>

          <Box>
            <Typography sx={styles.title}>Dias</Typography>

            <Row style={{ alignItems: 'center', gap: 12 }}>
              <Input
                title='Titulo do dia'
                placeholder='Digite o titulo do dia'
                value={dayTitle}
                onChange={(e) => setDayTitle(e.target.value)}
              />
              <Input
                type='date'
                title='Data do dia'
                placeholder='Digite a data do dia'
                value={dayDate}
                onChange={(e) => setDayDate(e.target.value)}
              />
              <Input
                title='Código'
                placeholder='Código de checkin'
                value={dayCode}
                onChange={(e) => setDayCode(e.target.value)}
              />

              <ButtonLoading
                label={'Adicionar dia'}
                onClick={() => addDay()}
                style={{ width: 200, height: 55, marginTop: 36 }}
              />

            </Row>

            <TableContainer component={Paper} sx={{ mt: 6 }}>
              <Table  >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Titulo</TableCell>
                    <TableCell align="center">Data</TableCell>
                    <TableCell align="center">Código</TableCell>
                    <TableCell align="center">ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {days && days.map((day: any) => (
                    <TableRow key={day.id}>
                      <TableCell align="center">{day.title}</TableCell>
                      <TableCell align="center">{formatDatePtBr(new Date(day.date))}</TableCell>
                      <TableCell align="center">{day.code}</TableCell>
                      <TableCell align="center">{day.id}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </Box>

        </DialogContent>

        <DialogActions style={{ alignItems: 'center', display: 'flex', height: 100 }}>
          <ButtonLoading label='Cancelar' isDelete onClick={handleClose} />
          <ButtonLoading label='Salvar evento' onClick={saveEvent} />
        </DialogActions>
      </Dialog>

    </Box>
  )
}

const styles: any = {
  container: {
    paddingTop: 10,
    marginLeft: 10,
    marginRight: 10,
    width: '100%'
  },

  title: {
    fontSize: 18,
    fontWeight: 600,
    color: '#1A1A1AFF',
    marginTop: 6
  },

}