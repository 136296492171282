import React, { useState } from 'react';
import TextField from "@mui/material/TextField";
import { Box } from '@mui/material';
import Typography from "@mui/material/Typography";

interface InputProps {
    title?: string;
    required?: boolean;
    placeholder?: string;
    value?: string;
    onChange?: (e: any) => void;
    type?: string;
    variant?: 'filled' | 'outlined' | 'standard';
    disabled?: boolean;
    autoComplete?: string;
    style?: React.CSSProperties;
    multiline?: boolean;
    rows?: number;
}

export default function Input(props: InputProps) {

    return (
        <Box sx={{ mt: 2 }}>
            <Typography
                component="span"
                variant="subtitle2"
                sx={{ flexGrow: 1, fontSize: 12, fontWeight: "500", color: "#9FA2B4" }}
            >
                {props.title ? props.title : ''}
            </Typography>
            <TextField
                disabled={props.disabled}
                variant={props.variant ? props.variant : "outlined"}
                required={props.required}
                fullWidth
                multiline={props.multiline}
                rows={props.rows ? props.rows : 1}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e: any) => props.onChange && props.onChange(e)}
                InputProps={{
                    style: {
                        borderRadius: "8px",
                        backgroundColor: "#FCFDFE",
                        justifyContent: "center",
                        ...props.style
                    }
                }}
                autoComplete={props.autoComplete ? props.autoComplete : "off"}
                type={props.type ? props.type : "text"}
            />
        </Box>
    );
}