import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

export default function NotFound() {
  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <h1 style={{ fontSize: 14, maxWidth: '20%', textAlign: 'center' }}>Opss! Página não encontrada!</h1>
      </Box>
    </Container>
  )
}