import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

const theme = createTheme({
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          padding: '0px',
          fontFamily: 'Poppins',
        },
      },
    }
  },
});

export default theme
