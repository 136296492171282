import React, { useState } from 'react';
import TextField from "@mui/material/TextField";
import { Box } from '@mui/material';
import Typography from "@mui/material/Typography";

interface RowProps {
    space?: boolean;
    children?: React.ReactNode;
    style?: Object;
}

export default function Row({ space, children, style }: RowProps) {

    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: space ? "space-between" : "flex-start", width: "100%", ...style }}>
            {children}
        </div>
    );
}