export function formatDatePtBr(date) {
  if (isValidDate(date)) {
    let dia = date.getUTCDate().toString().padStart(2, "0");
    let mes = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    let ano = date.getUTCFullYear();
    return dia + "/" + mes + "/" + ano;
  }
  return date;
}
export function formatTimestampToDate(unix_timestamp) {
  var a = new Date(unix_timestamp);
  var months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
  return time;
}

export function formatDateTimePtBr(date) {
  if (isValidDate(date)) {
    let dia = date.getDate().toString().padStart(2, "0");
    let mes = (date.getMonth() + 1).toString().padStart(2, "0");
    let ano = date.getUTCFullYear();
    let hour = date.getHours();
    let minutes = date.getMinutes();
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    return dia + "/" + mes + "/" + ano + " - " + hour + ":" + minutes;
  }
  return "";
}

function isValidDate(date) {
  return (
    date &&
    Object.prototype.toString.call(date) === "[object Date]" &&
    !isNaN(date)
  );
}

export function formatDateEn(date) {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  let ano = date.getUTCFullYear();
  let mes = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  let dia = date.getUTCDate().toString().padStart(2, "0");
  return ano + "-" + mes + "-" + dia;
}

export function convertDateToMonthStr(date) {
  let meses = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];
  return meses[date.getMonth()];
}

export function convertStringToDatePTBR(str) {
  if (str) {
    if (str.indexOf("/") > -1) {
      return str;
    }
    return formatDatePtBr(new Date(str));
  }
  return str;
}

export function convertDate(str) {
  if (str) {
    if (str.indexOf("/") > -1) {
      let split = str.split("/");
      let date = new Date();
      date.setFullYear(split[2], split[1] - 1, split[0]);
      return date;
    } else if (str.indexOf("-") > -1) {
      let split = str.split("-");
      let date = new Date();
      date.setFullYear(split[0], split[1] - 1, split[2]);
      return date;
    }
    return new Date(str);
  }
  return str;
}

export function toDay() {
  let current = new Date();
  current.setHours(0);
  current.setMinutes(0);
  current.setSeconds(0);
  return current;
}
