export const USER_DATA = "@user/user_data";
export const USER_UUID = "@user/user_uuid";
export const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export const colors = {
  primary: "#0F68BBFF",
  secondary: "#ECF1FE",
  background: "#F1F1F1FF",
  white: "#FFFFFF",
  black: "#0F0F0FFF",
  status: {
    red: "#E33939FF",
    green: "#4DD04DFF",
    yellow: "#FBC525FF",
    blue: "#0000FF",
  }
}