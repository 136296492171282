'use strict';

import { initializeApp } from "firebase/app";
import { getAuth, } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyA-hlYSL3wtRwEKwhjKc2r26ldVFkoV768",
  authDomain: "qazando-certificate-226d7.firebaseapp.com",
  projectId: "qazando-certificate-226d7",
  storageBucket: "qazando-certificate-226d7.firebasestorage.app",
  messagingSenderId: "940045274471",
  appId: "1:940045274471:web:c54daabcfbf64bfba473c2",
  measurementId: "G-550RC96D7D"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
